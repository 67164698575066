import Konva from "konva";
import {Position} from "./konva/position";


export class App {
    currentScale = 1;


    stage: Konva.Stage | undefined;
    layer: Konva.Layer | undefined;
    konvaContainer: HTMLElement | null | undefined;
    positions: Position[] = [];
    stuffs: Position[] = [];
    events: any[] = [];

    dragMode: boolean = false;
    dragElement: Position | undefined = undefined;


    constructor() {
        this.start();
    }

    on(name: string, _func: (args: any) => void) {
        //console.log(name, 'created');
        this.events.push({ name: name, func: _func});
    }

    emit(name: string, data: any = undefined) {
        const event = this.events.find( ( evt ) => evt.name === name);
        if ( event === undefined ) return console.log(`event ${name} not found!`);

        return event.func(data);
    }

    start() {
        this.konvaContainer = document.getElementById('container');
        if ( this.konvaContainer == null ) return //console.log('cannot find konva container!');

        this.stage = new Konva.Stage({
            container: 'container',
            width: this.konvaContainer.offsetWidth,
            height: this.konvaContainer.offsetHeight,
            draggable: false,
        });

        this.layer = new Konva.Layer();
        this.stage.add(this.layer);

        console.log('App Started');

        Konva.Image.fromURL('/plan.png', (node: any) => {
            if ( this.layer === undefined ) return;

            console.log(node.width(), node.height());

            node.x(Number('-' + node.width() / 2));
            node.y(Number('-' + node.height() / 2));
            node.id("image");
            this.layer.add(node);

            // HALLE 1
            this.stuffs.push(new Position(this, 1320, -70, 100, 150, "INFO", "#3c6c01", true, true, "", -5));

            this.positions.push(new Position( this, 1320, 100, 100, 100));
            this.positions.push(new Position( this, 1120, 30, 100, 180));
            this.positions.push(new Position( this, 1320, -240, 100, 120));
            this.positions.push(new Position( this, 1320, -360, 100, 120));
            this.positions.push(new Position( this, 1320, -470, 100, 120));
            this.positions.push(new Position( this, 1040, -360, 100, 200));
            this.positions.push(new Position( this, 890, 30, 100, 125));
            this.positions.push(new Position( this, 790, 160, 200, 100));
            this.positions.push(new Position( this, 590, 160, 200, 100));
            this.positions.push(new Position( this, 390, 160, 200, 100));
            this.positions.push(new Position( this, 190, 160, 200, 100));
            this.positions.push(new Position( this, -10, 160, 200, 100));
            this.positions.push(new Position( this, -210, 160, 200, 100));

            this.positions.push(new Position( this, 640, -150, 105, 100));
            this.positions.push(new Position( this, 510, -150, 130, 100));
            this.positions.push(new Position( this, 380, -150, 130, 100));
            this.positions.push(new Position( this, 250, -150, 130, 100));
            this.positions.push(new Position( this, 120, -150, 130, 100));
            this.positions.push(new Position( this, -30, -150, 150, 150));
            this.positions.push(new Position( this, -180, -150, 150, 150));

            this.positions.push(new Position( this, -360, -50, 90, 150));
            this.positions.push(new Position( this, -360, -200, 90, 150));
            this.positions.push(new Position( this, -360, -350, 90, 150));

            this.positions.push(new Position( this, -180, -250, 155, 100));
            this.positions.push(new Position( this, -25, -250, 155, 100));
            this.positions.push(new Position( this, 130, -250, 155, 100));
            this.positions.push(new Position( this, 285, -250, 155, 100));
            this.positions.push(new Position( this, 440, -250, 155, 100));
            this.positions.push(new Position( this, 590, -250, 155, 100));

            this.positions.push(new Position( this, 890, -310, 100, 120));
            this.positions.push(new Position( this, 890, -490, 100, 180));


            Konva.Image.fromURL('/img.png', (essen: any) => {
                essen.x(570);
                essen.y(-660);
                essen.width(120);
                essen.height(120);
                essen.id("foodImage");
                essen.on('click', () => this.emit("food"));
                this.layer!.add(essen);
            });

            this.stuffs.push(new Position(this, 510, -680, 230, 160, "food", "#3c6c01", true));
            this.positions.push(new Position( this, 360, -480, 130, 100));
            this.positions.push(new Position( this, 230, -480, 130, 100));
            this.positions.push(new Position( this, 100, -480, 130, 100));
            this.positions.push(new Position( this, -30, -480, 130, 100));
            this.positions.push(new Position( this, -180, -480, 150, 100));

            this.positions.push(new Position( this, -660, -580, 150, 100));
            this.positions.push(new Position( this, -810, -580, 150, 100));
            this.positions.push(new Position( this, -960, -580, 150, 100));
            this.positions.push(new Position( this, -1110, -580, 150, 100));
            this.positions.push(new Position( this, -1260, -580, 150, 100));

            this.positions.push(new Position( this, -1230, -400, 160, 100));
            this.stuffs.push(new Position(this, -1060, -400, 120, 100, "INFO", "#3c6c01", true, false, "", -10));

            // HALLE 2
            const halleGrundriss = new Konva.Line({
                points: [
                    -1690, -1310,
                    -1690, -310,
                    -2510, -310,
                    -2510, -1310,
                ],
                fill: '#dedede',
                stroke: 'black',
                strokeWidth: 3,
                closed: true,
            });
            this.layer.add(halleGrundriss);
            this.positions.push(new Position( this, -1800, -750, 100, 150));
            this.positions.push(new Position( this, -1800, -900, 100, 150));
            this.positions.push(new Position( this, -1800, -1050, 100, 150));

            this.positions.push(new Position( this, -1850, -1300, 150, 100));
            this.positions.push(new Position( this, -2000, -1300, 150, 100)); //47
            this.positions.push(new Position( this, -2150, -1300, 150, 100));
            this.positions.push(new Position( this, -2300, -1300, 150, 100));
            this.positions.push(new Position( this, -2500, -1280, 100, 180));

            this.positions.push(new Position( this, -2500, -1100, 100, 180));
            this.positions.push(new Position( this, -2500, -920, 100, 180));
            this.positions.push(new Position( this, -2500, -740, 100, 180));
            this.positions.push(new Position( this, -2500, -560, 100, 180));

            this.positions.push(new Position( this, -2350, -420, 300, 100));
            this.positions.push(new Position( this, -2050, -420, 200, 100));
            this.positions.push(new Position( this, -1850, -420, 150, 100));

            this.positions.push(new Position( this, -2050, -690, 100, 140));
            this.positions.push(new Position( this, -2050, -830, 100, 140));
            this.positions.push(new Position( this, -2050, -970, 100, 140));
            this.positions.push(new Position( this, -2050, -1110, 100, 140));

            this.positions.push(new Position( this, -2150, -1110, 100, 140));
            this.positions.push(new Position( this, -2150, -970, 100, 280));
            this.positions.push(new Position( this, -2150, -690, 100, 140));

            //Sonstiges / Aussen
            this.positions.push(new Position( this, -120, 470, 150, 225)); // 65
            this.positions.push(new Position( this, 800, 720, 120, 80)); // 66
            this.positions.push(new Position( this, 100, 700, 400, 100)); // 67
            this.positions.push(new Position( this, 200, 400, 490, 200, "11a")); // 68
            this.positions.push(new Position( this, 1450, 360, 150, 100, "31a")); // 69
            this.positions.push(new Position( this, 600, 700, 100, 100, "68")); // 70

            this.stuffs.push(new Position(this, 1050, 350, 370, 100, "EINGANG", "#c20101", true));

            Konva.Image.fromURL('/logo_new.png', (node: any) => {
                if ( !this.layer ) return;

                node.x(-1440);
                node.y(-1450);
                node.width(1500);
                node.height(600);
                node.id("image-logo-white");
                this.layer.add(node);
            });

            this.layer.add(new Konva.Arrow({
                x: -1300,
                y: -482,
                points: [0, 0, -200, 0],
                pointerLength: 30,
                pointerWidth: 40,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 40,
            }));

            this.layer.add(new Konva.Arrow({
                x: 1230,
                y: 500,
                points: [0, 200, 0, 0],
                pointerLength: 30,
                pointerWidth: 60,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 60,
            }));

            this.layer.add(new Konva.Arrow({
                x: -1800,
                y: -482,
                points: [0, 0, 200, 0],
                pointerLength: 30,
                pointerWidth: 40,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 40,
            }));

            this.layer.add(new Konva.Arrow({
                x: 1270,
                y: -350,
                points: [0, 600, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: 900,
                y: -100,
                points: [200, 0, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -150,
                y: -320,
                points: [950, 0, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -180,
                y: 100,
                points: [0, 0, 900, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: 795,
                y: -327,
                points: [0, 125, 0, 0],
                pointerLength: 0,
                pointerWidth: 0,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -250,
                y: -370,
                points: [0, 0, 0, 440],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -210,
                y: -460,
                points: [0, 0, 0, 150],
                pointerLength: 0,
                pointerWidth: 0,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -1202,
                y: -460,
                points: [1000, 0, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -1220,
                y: -420,
                points: [0, 0, 950, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -2150,
                y: -470,
                points: [0, 0, 200, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -2150,
                y: -1150,
                points: [200, 0, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -2150,
                y: -1150,
                points: [200, 0, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -2250,
                y: -1100,
                points: [0, 0, 0, 520],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Arrow({
                x: -1875,
                y: -1075,
                points: [0, 520, 0, 0],
                pointerLength: 30,
                pointerWidth: 15,

                fill: '#8e1818',
                stroke: '#8e1818',
                strokeWidth: 15,
            }));

            this.layer.add(new Konva.Text({
                x: -1365,
                y: -1030,
                text: 'www.oberkircher-berufsinfomesse.de',
                fontSize: 50,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));

            this.layer.add(new Konva.Text({
                x: -380,
                y: -870,
                text: 'HALLE 1',
                fontSize: 150,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));
            this.layer.add(new Konva.Text({
                x: -2500,
                y: -280,
                text: 'HALLE 2',
                fontSize: 150,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));

            if ( !this.stage ) return;

            const offset = 100;
            this.stage.absolutePosition({x: (this.stage.width() / 2) + offset, y: (this.stage.height() / 2) + offset});
            //this.stage.absolutePosition({x: -200, y: 200});
            this.zoom(1);
            this.onMouseWheel(undefined);
        });

        //this.stage.on('wheel', this.onMouseWheel.bind(this));
    }

    onMouseWheel( event: any ) {
        if ( !event ) return this.emit('zoom', (this.currentScale * 100));

        if ( event.evt.deltaY < 0 ) {
            this.currentScale += .1;
            //console.log('up');
        } else {
            //console.log('down');
            this.currentScale -= .1;
        }

        this.emit('zoom', (this.currentScale * 100));
    }

    zoom( scale: number = -1 ) {
        if ( this.stage === undefined ) return;


        if ( scale === -1 )
            return this.stage.scale({ x: 1, y: 1 });

        //if ( scale <= 0.4 ) scale = 0.4;
        //if ( scale >= 2 ) scale = 2;

        this.currentScale = scale;

        return this.stage.scale({ x: scale, y: scale });
    }
}