
export class StatisticTargetFilter {
    branches: string[] = [];
    arts: string[] = [];
    schulisch: boolean = false;
    ausbildung: boolean = false;
    studium: boolean = false;
    companies: number[] = [];
}
export class Statistic {
    constructor(public type: string) {}
    filter?: string;
    target?: string;
}