

export interface Branches {
    name: string;
    color: string;
    label?: string;
    key?: string;
}

export const branches: Branches[] = [
    {name: 'bildungsweg', color: '#88B84A', label: 'Schulische Bildungswege'},
    {name: 'dienstleistung', color: '#D18200', label: 'Dienstleistung'},
    {name: 'finanzwesen', key: 'finanzen', color: '#767676', label: 'Finanzen'},
    {name: 'gastronomie-hotellerie', color: '#9F020C', label: 'Gastronomie & Hotellerie'},
    {name: 'gesundheitswesen', color: '#63A8B4', label: 'Gesundheit & Soziales'},
    {name: 'handel', color: '#943E81', label: 'Handel'},
    {name: 'handwerk', color: '#42617D', label: 'Handwerk'},
    {name: 'industrie', color: '#5B492F', label: 'Industrie'},
    {name: 'logistik', color: '#8D5005', label: 'Logistik'},
    {name: 'verwaltung', color: '#315406', label: 'Öffentlicher Dienst'},
]