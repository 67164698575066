import React from 'react';
import {App} from "./app/app";
import {
    Button,
    Carousel,
    Collapse,
    Form,
    Layout,
    Modal,
    Row,
    Select,
    Slider,
    Space,
    Spin,
    Switch,
    Tabs,
    Typography
} from "antd";
import axios from "axios";
import {Company} from "./app/interfaces/company";
import {Positions} from "./app/interfaces/positions";
import {QRCode} from 'react-qrcode-logo';
import Logo from './Logo.png';
import {branches} from "./app/configs/colors";
import {Filter} from "./app/models/filter";
import {Position} from "./app/konva/position";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {API} from "./app/api";
import {Vector2d} from "konva/lib/types";
import {FilterTypes} from "./app/enums/filter";
import {berufeList} from "./app/configs/display";
import {useParams} from "react-router-dom";
import {Statistic} from "./app/interfaces/stats";


export function Application() {
    const { zoom } = useParams();

    return <ApplicationController zoom={zoom} />;
}

interface IProps {
    zoom?: string;
}
interface IStates {
    inActive: boolean,
    selectedCompanies: Company[] | undefined;
    selectedCompanyID: number;
    selectedPosition: Position | null;
    companies: Company[];
    filterCompanies: any[];
    zoom: number;
    showFilter: boolean;
    filterOptionsBranches: any[];
    filterArts: any[];
    filterOptionsArts: any[];
    filter: Filter;

    allBranches: any[];
    allAngebote: any[];

    isFoodModalOpen: boolean;
}

class ApplicationController extends React.Component<IProps, IStates> {
    application: App | undefined;
    inactiveInterval: any = undefined;
    inActiveTime: number = 0;
    inActivePosition: number = 1;
    verticalBar: React.CSSProperties = {
        display: 'inline-block',
        height: 300,
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            inActive: false,
            selectedCompanies: undefined,
            selectedCompanyID: 0,
            selectedPosition: null,

            companies: [],
            filterCompanies: [],
            zoom: 100,
            showFilter: true,
            filterOptionsBranches: [],
            filterArts: [],
            filterOptionsArts: [],
            filter: new Filter(),

            allBranches: [],
            allAngebote: [],
            isFoodModalOpen: false,
        }

        if ( this.inactiveInterval )
            clearInterval(this.inactiveInterval);

        if ( !this.inactiveInterval )
            this.inactiveInterval = setInterval(this.checkingInactive.bind(this), 1000);
    }

    lerpLinear(value1: number, value2: number, amount: number) {
        amount = amount < 0 ? 0 : amount;
        amount = amount > 1 ? 1 : amount;
        return value1 + (value2 - value1) * amount;
    };

    lerp (start: number, end: number, amt: number) {
        return (1-amt)*start+amt*end
    }

    focusPosition(position: number = 1, zoom: number = 100) {
        if ( !this.application ) return;

        let zoomOut: boolean = true;
        let targetPosition: Vector2d | null = null;
        let pos;

        let interval: any = setInterval( () => {
            if ( !this.application ) return clearInterval(interval);
            if ( !this.application.stage ) return clearInterval(interval);

            let currentZoom = this.application.currentScale * 100;
            let currentPos = this.application.stage.absolutePosition();

            if ( zoomOut ) {
                if ( currentZoom <= 40 ) zoomOut = false;
                if ( currentZoom <= zoom ) {
                    currentZoom += 1;
                    this.onZoomChanged(currentZoom);
                }
            } else {
                // wenn der zoom noch nicht erreicht ist
                if ( currentZoom <= zoom ) {
                    currentZoom += 1;
                    this.onZoomChanged(currentZoom);
                } else {
                    if ( !targetPosition ) {
                        pos = this.application.positions.find( (posi) => posi.positionID === position);
                        if ( !pos ) return;
                        if ( !pos.box ) return;
                        targetPosition = pos.box.absolutePosition();
                    }

                    currentPos.x = this.lerpLinear(currentPos.x, targetPosition.x, 0.009);
                    currentPos.y = this.lerpLinear(currentPos.y, targetPosition.y, 0.009);

                    /*if ( (targetPosition.x + 5) <= currentPos.x && (targetPosition.x - 5) >= currentPos.x) return;

                    // prüfe ob position erreicht ist
                    if ( currentPos.x !== targetPosition.x) {
                        if ( currentPos.x < targetPosition.x ) { // kleiner als box
                            currentPos.x -= 1;
                        } else if ( currentPos.x > targetPosition.x ) {
                            currentPos.x += 1;
                        } else {
                            // sollte erreicht sein, nichts tun hier
                        }
                    }*/

                    this.application.stage.absolutePosition(currentPos);
                    //console.log('Box:', targetPosition, 'Stage:', currentPos);
                }
            }

        }, 10);

    }

    checkingInactive() {
        if ( this.inActiveTime >= 60 ) {
            this.setState({
                inActive: true
            })
        }
    }

    async getBackendData() {
        const positions = await axios.get(API.URL + 'positions/');
        const companies = await axios.get(API.URL + 'companies/');
        /*const filteredPosition: any[] = [];
        console.log(positions.data);
        for ( const pos of positions.data ) {
            if ( !filteredPosition[pos.position] ) {
                filteredPosition[pos.position] = {
                    position: pos.position,
                    company: [pos.company]
                };
            } else {
                filteredPosition[pos.position].company.push(pos.company);
            }
        }
        console.log(filteredPosition);*/

        const filterBranches: any[] = [];
        const filterArts: any[] = [];
        for ( const company of companies.data ) {
            if ( company.value3 ) {
                if ( company.value3.startsWith("{") || company.value3.startsWith("[") ) {
                    company.value3 = JSON.parse(company.value3);
                }
            }
            if ( company.value7 ) {
                if ( company.value7.startsWith("{") || company.value7.startsWith("[") ) {
                    company.value7 = JSON.parse(company.value7);
                }
            }
            if ( company.value8 ) {
                if ( company.value8.startsWith("{") || company.value8.startsWith("[") ) {
                    company.value8 = JSON.parse(company.value8);
                }
            }
            if ( company.value9 ) {
                if ( company.value9.startsWith("{") || company.value9.startsWith("[") ) {
                    company.value9 = JSON.parse(company.value9);
                }
            }
            if ( company.value10 ) {
                if ( company.value10.startsWith("{") || company.value10.startsWith("[") ) {
                    company.value10 = JSON.parse(company.value10);
                }
            }

            if ( !company.value10  ) continue;


            for ( const ausbildung of company.value10 ) {
                if ( !ausbildung.branche ) continue;
                if ( ausbildung.branche.length === 0 ) continue;
                if ( !ausbildung.bezeichnung ) continue;
                if ( ausbildung.bezeichnung === 0 ) continue;

                const branchLabel = ausbildung.branche;
                const branchLabelFirst = branchLabel[0].toUpperCase();

                const objectBranch = {
                    key: `${ausbildung.branche}`,
                    label: branchLabelFirst + branchLabel.slice(1, branchLabel.length),
                    value: ausbildung.branche.toLowerCase(),
                }

                const branch = branches.find( ( branc ) => branc.name === objectBranch.value || branc.key && branc.key === objectBranch.value);
                if ( !branch ) continue;

                objectBranch.label = branch.label!;

                if ( !filterBranches.find( ( item ) => item.value === ausbildung.branche.toLowerCase()))
                filterBranches.push(objectBranch);

                const object = {
                    key: `ausbildung_${ausbildung.bezeichnung}`,
                    label: ausbildung.bezeichnung,
                    value: ausbildung.bezeichnung.toLowerCase(),
                }

                if ( !filterArts.find( ( item ) => item.label === ausbildung.bezeichnung))
                    filterArts.push(object);
            }
        }

        const sort = filterArts.sort(function(a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        // @ts-ignore
        this.setState({
            companies: companies.data,
            filterOptionsBranches: filterBranches.sort(this.compareOption.bind(this)),
            filterArts: sort,
            filterOptionsArts: sort,
            allAngebote: filterArts,
            allBranches: filterBranches
        }, () => {
            if ( !this.application ) return console.log('application not ready!');

            const filteredPosition = positions.data as Positions[];


            const filterCompanies: any[] = [];
            for ( const position of this.application.positions) {
                if ( !position ) continue;

                const companyIds = filteredPosition.filter( ( fPos ) => fPos.position === position.positionID );
                if ( companyIds.length === 0 ) {
                    continue;
                }

                for ( const companyId of companyIds ) {
                    const compInfo = this.state.companies.find( ( comp ) => comp.id && comp.id === companyId.company);
                    if ( !compInfo ) {
                        console.log('cant find company information of:', companyId.company, 'pos:', companyId.position);
                        position.canHovered = false;
                        position.highlight();
                        continue;
                    }

                    position.companies.push(Object.assign({standNummer: companyId.position}, compInfo));

                    if ( filterCompanies.find(( filt ) => filt.key === 'company_' + compInfo.id)) continue;
                    filterCompanies.push({
                        key: 'company_' + compInfo.id,
                        label: compInfo.value3.inhalt1,
                        value: compInfo.id
                    });
                    //console.log('pushed:', companyId.company, 'to position:', companyId.position, '?', position.positionID);

                }


                /*const posInfo = filteredPosition.find( ( pos ) => pos && pos.position === position.positionID);
                if ( !posInfo ) {
                    position.canHovered = false;
                    position.highlight();
                    continue;
                }*/

                /*const companyInformation = this.state.companies.filter( ( comp ) => posInfo.company.includes(comp.id));
                if ( !companyInformation ) {
                    console.log('cant merge company Information to object:', posInfo.company, 'not found in companie list');
                    position.canHovered = false;
                    position.highlight();
                    continue;
                }

                console.log('companyInformation:', companyInformation);

                for ( const comp of companyInformation ) {
                    position.companies.push(Object.assign({standNummer: posInfo.position}, comp));

                    if ( filterCompanies.find(( filt ) => filt.key === 'company_' + comp.id)) continue;
                    filterCompanies.push({
                        key: 'company_' + comp.id,
                        label: comp.value3.inhalt1,
                        value: comp.id
                    });
                }*/

                //console.log(position.companies);
            }

            // @ts-ignore
            this.setState({
                filterCompanies: filterCompanies.sort(this.compareOption.bind(this))
            });
        });
    }

    sortValues() {
        // @ts-ignore
        this.setState({
            filterCompanies: this.state.filterCompanies.sort((a, b)=> {
                if ( a.label < b.label ) {
                    return -1;
                }
                if ( a.label > b.label ) {
                    return 1;
                }
                return 0;
            }),
            filterOptionsBranches: this.state.filterOptionsBranches.sort((a, b) => {
                if ( a.label < b.label ) {
                    return -1;
                }
                if ( a.label > b.label ) {
                    return 1;
                }
                return 0;
            })
        });
    }

    componentDidMount() {
        this.application = new App();
        this.application.on('info', this.onShowInfo.bind(this));
        this.application.on('food', this.onFood.bind(this));
        this.application.on('zoom', this.onZoomChanged.bind(this));

        this.getBackendData();

        setTimeout( () => {
            const zoom = Number(this.props.zoom) || 40;
            //console.log('zoom:', zoom);
            this.onZoomChanged(zoom);
        }, 500);
    }

    onFood() {
        // @ts-ignore
        this.setState({
            isFoodModalOpen: true
        })

    }

    async onShowInfo(position: Position) {
        let targetID = 0;
        if ( position.companies.length > 0 ) {
            targetID = (position.companies.length - 1);
        }

        try {
            const company = position.companies.at(0);
            if ( !company ) throw new Error("no company found");

            const nStats = new Statistic("click");
            nStats.target = position.label || (position.id + 1).toString();
            const statsFilter = {
                id: company.id,
                article_id: company.article_id,
                name: this.getCompanyTitle(company)
            }
            nStats.filter = JSON.stringify(statsFilter);
            const statsResult = await axios.post(API.URL + 'stats', nStats);
        } catch (error) {}

        //this.focusPosition(position.positionID);

        // @ts-ignore
        this.setState({
            selectedPosition: position,
            selectedCompanies: position.companies,
            selectedCompanyID: targetID
        });
    }

    onModalCancel() {
        // @ts-ignore
        this.setState({selectedCompanies: undefined, selectedCompanyID: 0});
    }

    onZoomChanged(value: number) {
        // @ts-ignore
        this.setState({
            zoom: value
        }, () => {
            this.application?.zoom(this.state.zoom / 100);
        })
    }

    getCompanyTitle(company: Company): string {
        if ( !company ) return 'error';
        if ( typeof(company.value3) === "object" ) {
            return company.value3.inhalt1;
        } else {
            return company.value3;
        }
    }

    async onFilterChanged() {
        this.inActiveTime = 0;
        if ( !this.application ) return;

        const filter = this.state.filter;
        if ( !filter ) return;

        if ( filter.companies.length !== 0 ) {
            // clear other fields:
            filter.branches = [];
            filter.arts = [];
            filter.schulisch = false;
            filter.ausbildung = false;
            filter.studium = false;

            this.setState({
                filter: filter
            });
        }


        // Get all arts for branches
        const filterArts: any[] = [];

        for ( const company of this.state.companies ) {
            if ( !company.value10 ) continue;
            if ( typeof(company.value10) !== "object" ) continue;

            for ( const job of company.value10 ) {
                if ( filter.branches.length !== 0 ) {
                    if ( filter.branches.includes(job.branche) ) {
                        const object = {
                            key: `ausbildung_${job.bezeichnung}`,
                            label: job.bezeichnung,
                            value: job.bezeichnung.toLowerCase(),
                        }

                        if ( !filterArts.find( ( item ) => item.value === job.bezeichnung.toLowerCase())) {
                            filterArts.push(object);
                        }
                    }
                } else {
                    const object = {
                        key: `ausbildung_${job.bezeichnung}`,
                        label: job.bezeichnung,
                        value: job.bezeichnung.toLowerCase(),
                    }

                    if ( !filterArts.find( ( item ) => item.value === job.bezeichnung.toLowerCase())) {
                        filterArts.push(object);
                    }
                }
            }
        }

        this.setState({
            filterOptionsArts: filterArts.sort(this.compareOption.bind(this))
        });

        // filter setups
        if (filter.branches.length === 0) {
            if ( filter.schulisch ) {
                const filterArts: any[] = [];

                for ( const company of this.state.companies ) {
                    if ( !company.value10 ) continue;
                    if ( typeof(company.value10) !== "object" ) continue;

                    for ( const job of company.value10 ) {
                        if ( job.ausbildungsart !== "Bildungsweg") continue;
                        const object = {
                            key: `ausbildung_${job.bezeichnung}`,
                            label: job.bezeichnung,
                            value: job.bezeichnung.toLowerCase(),
                        }

                        if ( !filterArts.find( ( item ) => item.value === job.bezeichnung.toLowerCase())) {
                            filterArts.push(object);
                        }
                    }
                }

                this.setState({
                    filterOptionsArts: filterArts
                });
            } else if ( filter.ausbildung ) {
                const filterArts: any[] = [];

                for ( const company of this.state.companies ) {
                    if ( !company.value10 ) continue;
                    if ( typeof(company.value10) !== "object" ) continue;

                    for ( const job of company.value10 ) {
                        if (
                            job.ausbildungsart.toLowerCase() === "ausbildung" ||
                            job.ausbildungsart.toLowerCase() === "schulischeausbildung" ||
                            //job.ausbildungsart.toLowerCase() === "bildungsweg" ||
                            job.ausbildungsart.toLowerCase() === "ausbildungdual"
                        ) {
                            const object = {
                                key: `ausbildung_${job.bezeichnung}`,
                                label: job.bezeichnung,
                                value: job.bezeichnung.toLowerCase(),
                            }

                            if ( !filterArts.find( ( item ) => item.value === job.bezeichnung.toLowerCase())) {
                                filterArts.push(object);
                            }
                        }
                    }
                }

                this.setState({
                    filterOptionsArts: filterArts
                });
            } else if ( filter.studium ) {
                const filterArts: any[] = [];

                for ( const company of this.state.companies ) {
                    if ( !company.value10 ) continue;
                    if ( typeof(company.value10) !== "object" ) continue;

                    for ( const job of company.value10 ) {
                        if ( job.ausbildungsart !== "Studium") continue;
                        const object = {
                            key: `ausbildung_${job.bezeichnung}`,
                            label: job.bezeichnung,
                            value: job.bezeichnung.toLowerCase(),
                        }

                        if ( !filterArts.find( ( item ) => item.value === job.bezeichnung.toLowerCase())) {
                            filterArts.push(object);
                        }
                    }
                }

                this.setState({
                    filterOptionsArts: filterArts
                });
            }
        }



        try {
            const nStats = new Statistic("filter");
            nStats.filter = JSON.stringify(filter);
            const statsResult = await axios.post(API.URL + 'stats', nStats);
        } catch (error) {}

        ///////////////////// only highlighting /////////////////////
        for ( const pos of this.application?.positions ) {
            const filter = this.state.filter;
            if (
                !filter.schulisch &&
                !filter.studium &&
                !filter.ausbildung &&
                filter.arts.length === 0 &&
                filter.branches.length === 0 &&
                filter.companies.length === 0
            ) {
                //console.log('none filter');
                pos.filter = FilterTypes.NONE
            } else {
                //console.log('all pos disabled');
                pos.filter = FilterTypes.DISABLED
            }


            if ( !pos.companies ) continue;

            if ( filter.companies.length !== 0 ) {
                //console.log('[SUCHE] nur nach Firmen');
                //console.log(filter.companies, pos.companies);
                for ( const company of pos.companies ) {
                    if ( !company ) continue;

                    if ( filter.companies.includes(company.id as number) ) {
                        pos.filter = FilterTypes.HIGHLIGHTED;
                    }
                }

                pos.reset();
                continue;
            }

            if ( filter.branches.length !== 0 && filter.arts.length === 0 ) {
                //console.log('[SUCHE] nur nach branches');

                for ( const company of pos.companies ) {
                    for ( const job of company.value10 ) {
                        console.log(job.ausbildungsart);
                        if ( filter.schulisch && job.ausbildungsart !== "Bildungsweg") continue;
                        if ( filter.ausbildung && job.ausbildungsart !== "Ausbildung") continue;
                        if ( filter.studium && job.ausbildungsart !== "Studium" ) continue;
                        if ( filter.branches.includes(job.branche.toLowerCase()) ) {
                            pos.filter = FilterTypes.HIGHLIGHTED;
                        }
                    }
                }
            } else if ( filter.branches.length !== 0 && filter.arts.length !== 0 ) {
                //console.log('[SUCHE] nach branches & angebote');
                for ( const company of pos.companies ) {
                    for ( const job of company.value10 ) {
                        if ( filter.schulisch && job.ausbildungsart !== "Bildungsweg") continue;
                        if ( filter.ausbildung && job.ausbildungsart !== "Ausbildung") continue;
                        if ( filter.studium && job.ausbildungsart !== "Studium" ) continue;
                        if ( filter.arts.includes(job.bezeichnung.toLowerCase())) {
                            pos.filter = FilterTypes.HIGHLIGHTED;
                        }
                    }
                }
            } else if (filter.branches.length === 0 && filter.arts.length !== 0) {
                //console.log(`[SUCHE] nur nach angebote`);
                for ( const company of pos.companies ) {
                    for ( const job of company.value10 ) {
                        console.log('checking:', job.bezeichnung, filter.arts);
                        if ( filter.arts.includes(job.bezeichnung.toLowerCase()) ) {
                            pos.filter = FilterTypes.HIGHLIGHTED;
                        }
                    }
                }
            } else if ( filter.branches.length === 0 && filter.arts.length === 0 ) {
                //console.log('[SUCHE] nur nach Switches');
                for ( const company of pos.companies ) {
                    for ( const job of company.value10 ) {
                        if ( filter.ausbildung ) {
                            if (
                                job.ausbildungsart.toLowerCase() === "ausbildung" ||
                                job.ausbildungsart.toLowerCase() === "schulischeausbildung" ||
                                //job.ausbildungsart.toLowerCase() === "bildungsweg" ||
                                job.ausbildungsart.toLowerCase() === "ausbildungdual"
                            ) {
                                pos.filter = FilterTypes.HIGHLIGHTED;
                            }
                        }
                        if ( filter.schulisch && job.ausbildungsart.toLowerCase() === 'bildungsweg' ) {
                            pos.filter = FilterTypes.HIGHLIGHTED;
                        }
                        if ( filter.studium && job.ausbildungsart === 'Studium' ) {
                            pos.filter = FilterTypes.HIGHLIGHTED;
                        }
                    }
                }
            }


            pos.reset();
        }
    }

    resetFilter() {
        if ( !this.application ) return;

        for ( const pos of this.application.positions ) {
            pos.filter = FilterTypes.NONE;
            pos.reset()
            if ( pos.companies.length === 0 ) {
                pos.highlight();
                continue;
            }
        }

        this.setState({filter: new Filter()});
    }

    compareBezeichnung(a: any, b: any) {
        if ( a.bezeichnung.toLowerCase() < b.bezeichnung.toLowerCase() ){
            return -1;
        }
        if ( a.bezeichnung.toLowerCase() > b.bezeichnung.toLowerCase() ){
            return 1;
        }
        return 0;
    }

    compareOption(a: any, b: any) {
        if ( a.label.toLowerCase() < b.label.toLowerCase() ) {
            return -1;
        }
        if ( a.label.toLowerCase() > b.label.toLowerCase() ) {
            return 1;
        }
        return 0;
    }

    displayBerufe(company: Company) {
        if ( !company.value10 ) return <div>Nichts da</div>;

        const array = [];

        for ( const list of berufeList ) {
            let filteredList = company.value10.filter( (job: any) => job.ausbildungsart.toLowerCase() === list.key);
            filteredList = filteredList.sort(this.compareBezeichnung);
            if ( filteredList.length != 0 ) {
                array.push({
                    label: list.label,
                    items: filteredList
                });
            }
        }

        return array.map( ( arr ) => {
            return <>
                <div>
                    <Typography.Title level={4}>{arr.label}</Typography.Title>
                </div>
                <Collapse>
                    {
                        arr.items.map( ( item: any, index: number) => {
                            const branch = branches.find( (el ) => el.name === item.branche.toLowerCase());
                            if ( branch ) return <Collapse.Panel
                                key={index.toString()}
                                header={<span style={{color: "white", fontWeight: 'bold'}}>{item.bezeichnung}</span>}
                                style={{backgroundColor: branch.color}}
                            >
                                {item.beschreibung.replaceAll("<br>", "\n")}
                                <div style={{marginTop: 20}}>
                                    <div><strong>Ausbildungsdauer:</strong></div>
                                    <div>{item.dauer}</div>
                                    <div><strong>Mindestvoraussetzung:</strong></div>
                                    <div>{item.voraussetzung}</div>
                                </div>
                            </Collapse.Panel>
                        })
                    }
                </Collapse>
            </>
        })
    }

    getStandNumberText() {
        if ( !this.state.selectedPosition || !this.state.selectedCompanies ) return "";

        return this.state.selectedPosition.label || (this.state.selectedPosition.id + 1).toString();
    }

    render() {
        // @ts-ignore
        return <>
            <div>
                <Modal title="Essensangebot" open={this.state.isFoodModalOpen} onOk={() => {this.setState({isFoodModalOpen: false})}} onCancel={() => {this.setState({isFoodModalOpen: false})}} width={'35%'}>
                    <img src={'/essensangebot.png'} width={'100%'} />
                </Modal>
            </div>
            <div className="filter-content">
                <Form
                    layout="vertical"
                    onValuesChange={this.onFilterChanged.bind(this)}
                    disabled={this.state.filter.companies.length !== 0}
                >
                    <Typography.Title level={4}>Nach was suchst du?</Typography.Title>
                    <Space>
                        <Form.Item label="Ausbildung">
                            <Switch
                                checked={this.state.filter.ausbildung}
                                onChange={(checked, event) => {
                                    const filter = this.state.filter;
                                    filter.ausbildung = checked
                                    this.setState({
                                        filter: filter
                                    }, () => this.onFilterChanged())
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Studium">
                            <Switch
                                checked={this.state.filter.studium}
                                onChange={(checked, event) => {
                                    const filter = this.state.filter;
                                    filter.studium = checked
                                    this.setState({
                                        filter: filter
                                    }, () => this.onFilterChanged())
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="Schulisch">
                            <Switch
                                checked={this.state.filter.schulisch}
                                onChange={(checked, event) => {
                                    const filter = this.state.filter;
                                    filter.schulisch = checked
                                    this.setState({
                                        filter: filter
                                    }, () => this.onFilterChanged())
                                }}
                            />
                        </Form.Item>
                    </Space>

                    <Form.Item label="Branche">
                        <Select
                            id="select_branche"
                            mode="multiple"
                            allowClear
                            size={"large"}
                            style={{ width: 350 }}
                            onChange={ (value) => {
                                const filter = this.state.filter;
                                filter.branches = value;
                                this.setState({filter: filter}, () => this.onFilterChanged());
                            }}
                            options={this.state.filterOptionsBranches}
                            value={this.state.filter.branches}
                        />
                    </Form.Item>

                    <Form.Item label="Angebote (m/w/d)">
                        <Select
                            id="select_type"
                            mode="multiple"
                            allowClear
                            size={"large"}
                            style={{ width: 350 }}
                            onChange={ (value) => {
                                const filter = this.state.filter;
                                filter.arts = value;
                                this.setState({filter: filter}, () => this.onFilterChanged());
                            }}
                            options={this.state.filterOptionsArts}
                            value={this.state.filter.arts}
                        />
                    </Form.Item>

                </Form>
                <Button onClick={() => this.resetFilter()}>
                    Suche Zurücksetzen
                </Button>
            </div>
            <div className="filter-companies">
                <Typography.Title level={4}>Unternehmen A-Z</Typography.Title>
                <Select
                    id="select_company"
                    mode="multiple"
                    allowClear
                    size={"large"}
                    style={{ width: 350 }}
                    onChange={ (value) => {
                        const filter = this.state.filter;
                        filter.companies = value;
                        this.setState({filter: filter}, () => this.onFilterChanged());
                    }}
                    options={this.state.filterCompanies}
                    value={this.state.filter.companies}
                />
            </div>
            <div className="controls" style={{display: 'none'}}>
                <div style={this.verticalBar}>
                    <Slider
                        vertical
                        min={40}
                        max={200}
                        marks={{
                            100: '100%'
                        }}
                        value={this.state.zoom}
                        trackStyle={{
                            backgroundColor: 'blue'
                        }}
                        onChange={this.onZoomChanged.bind(this)}
                    />
                </div>
            </div>
            <Layout.Content>
                <div id={"container"} style={{position: 'absolute', width: '100%', height: '100%'}}></div>
                { this.state.selectedCompanies !== undefined && this.state.selectedCompanies.length !== 0 &&
                    <>
                        <Modal
                            destroyOnClose={true}
                            className="right"
                            centered={false}
                            style={{ top: 20 }}
                            open={true}
                            title={"Wir haben dich neugierig gemacht?\n" +
                                "Hier findest du noch mehr Infos:"}
                            width={250}
                            footer={[]}
                        >
                            <Row justify={"center"}>
                                <QRCode
                                    value={"https://oberkircher-berufsinfomesse.de/index.php?article_id="+this.state.selectedCompanies[this.state.selectedCompanyID].article_id}
                                    logoImage={Logo}
                                    size={200}
                                />
                            </Row>
                        </Modal>
                        <Modal
                            destroyOnClose={true}
                            className="left"
                            centered={false}
                            style={{ top: 20 }}
                            open={true}
                            onCancel={this.onModalCancel.bind(this)}
                            onOk={this.onModalCancel.bind(this)}
                            width={980}
                            mask={false}
                            footer={[]}
                        >
                            { this.state.selectedCompanies !== undefined &&
                                <>
                                    { this.state.selectedCompanies.length !== 1 &&
                                        <Tabs
                                            defaultActiveKey={`${this.state.selectedCompanyID}`}
                                            items={this.state.selectedCompanies.map((company, index) => {
                                                return {
                                                    key: index.toString(),
                                                    label: this.getCompanyTitle(company),
                                                    children: '',
                                                } as any
                                            })}
                                            onChange={(key) => {
                                                //console.log('tab changed:', key);
                                                this.setState({
                                                    selectedCompanyID: Number(key)
                                                })
                                            }}
                                        />
                                    }

                                    <Row justify="space-between" align={"middle"} wrap={false}>
                                        <div><Typography.Title level={3}>{this.getCompanyTitle(this.state.selectedCompanies[this.state.selectedCompanyID])}</Typography.Title></div>
                                        <div>
                                            <img
                                                src={"https://oberkircher-berufsinfomesse.de/index.php?rex_media_type=full&rex_media_file=" + this.state.selectedCompanies[this.state.selectedCompanyID].media2}
                                                width="auto"
                                                style={{marginRight: 10, maxHeight: 150, maxWidth: 250}}
                                            />
                                        </div>
                                    </Row>

                                    { this.state.selectedCompanies[this.state.selectedCompanyID] &&
                                        <>
                                            <div>{this.state.selectedCompanies[this.state.selectedCompanyID].value4}</div>
                                            <Carousel
                                                autoplay
                                                effect="fade"
                                                pauseOnDotsHover={false}
                                                pauseOnHover={false}
                                                dotPosition={"top"}
                                                style={{backgroundColor: '#c6c6cc', marginBottom: 20, marginTop: 20}}
                                            >
                                                { this.state.selectedCompanies[this.state.selectedCompanyID] &&
                                                    this.state.selectedCompanies[this.state.selectedCompanyID].medialist5 && this.state.selectedCompanies[this.state.selectedCompanyID].medialist5?.split(",").map( (imageName: string) => {
                                                        return <div>
                                                            <Row justify="center">
                                                                <LazyLoadImage
                                                                    alt={"Bild"}
                                                                    height={"400px"}
                                                                    src={"https://oberkircher-berufsinfomesse.de/index.php?rex_media_type=full&rex_media_file=" + imageName} // use normal <img> attributes as props
                                                                    width={"auto"}
                                                                    placeholder={<Spin tip="Loading" size="small" />}
                                                                />
                                                            </Row>
                                                        </div>
                                                    })
                                                }
                                            </Carousel>

                                            {this.displayBerufe(this.state.selectedCompanies[this.state.selectedCompanyID])}

                                            <Row justify={"center"} align="middle" style={{marginTop: 20}}>
                                                <div style={{marginRight: 20, fontWeight: 'bold', fontSize: 20}}>STAND: </div>
                                                <div className="company_position_info">{this.getStandNumberText()}</div>
                                            </Row>
                                        </>
                                    }
                                </>
                            }
                        </Modal>
                    </>
                }
            </Layout.Content>
        </>
    }
}
