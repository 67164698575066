import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Application} from './App';
import {API} from "./app/api";
import {BrowserRouter, Route, Routes} from "react-router-dom";


//API.URL = "https://api.oberkircher-berufsinfomesse.de/";
if ( process.env.NODE_ENV === "production") {
    API.URL = "https://api.oberkircher-berufsinfomesse.de/";
} else {
    API.URL = (window.location.origin + '/').replace(':3000', ':3030');
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path={"/:zoom"} element={<Application />} />
            <Route path={"/"} element={<Application />} />
        </Routes>
    </BrowserRouter>
);
